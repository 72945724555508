<template>
	<div>
		<AlertMessage
			v-if="prettyError"
			:component="{
				content: {
					messageText: prettyError,
				},
			}"
		/>
		<section
			v-if="!prettyError"
			class="address-search"
			:class="{ isShakeSearchField: isShakeSearchField }"
		>
			<div>
				<TnInputText
					:text="content?.textBefore"
					:placeholder="content?.placeholderText || 'Gateadresse, f.eks.: Telenorveien 09000'"
					:suggestions="formattedSuggestions"
					:iconLeft="content?.iconLeft || 'home'"
					@update:modelValue="handleInput"
					@clearSuggestions="clearSuggestions"
					@suggestionSelected="suggestionSelected"
					:modelValue="query"
					autocomplete="off"
					class="input"
					name="q"
					:dark="dark"
					:loading="loading"
					solo
					:hide-details="true"
					@click="handleFocus"
					@focused="handleFocus"
					:size="size"
					data-cy="address-search-input"
					:autofocus="autofocus"
				/>
				<TnSupportText
					v-if="!propertySearchExcluded"
					class="margin-top-s"
				>
					<TnParagraph
						:dark="dark"
						size="xs"
						>Har du ikke gateadresse?
						<a
							href="javascript:"
							class="reverse-underline"
							@click="$emit('changeComponent', 'propertySearch')"
							>Søk her</a
						>.</TnParagraph
					>
				</TnSupportText>
			</div>
		</section>
		<RichText
			v-if="content.textAfter"
			class="margin-top-m"
			:text="content.textAfter"
			:dark="dark"
		></RichText>
		<div class="margin-top-l class ctaButtons">
			<TnButton
				v-if="content.primaryCtaButton?.text"
				:dark="dark"
				@click.prevent="ctaClicked"
				responsive
				>{{ content.primaryCtaButton.text }}</TnButton
			>
			<TnButton
				v-if="secondaryCtaButton?.text"
				:dark="dark"
				secondary
				:to="secondaryCtaButton.link.to"
				responsive
				>{{ secondaryCtaButton.text }}</TnButton
			>
		</div>
	</div>
</template>

<script>
import { isMinimumDifferent } from "~/components/broadband-consumer/AddressSearch/addressSearchModel.js";
import SupportsDarkMode from "../../../mixins/platform/SupportsDarkMode.js";
import TelenorIdUserMixin from "~/mixins/platform/TelenorIdUserMixin.js";
import telenorid from "~/telenorid/telenorid";
import { mapGetters } from "vuex";
import FormatToPrettyError from "~/helpers/formatting/formatToPrettyError";
import LinkViewModel from "~/helpers/ViewModels/LinkViewModel.js";

import {mapState} from 'pinia';

export default defineNuxtComponent({
	name: "StandaloneAddressSearch",
	mixins: [TelenorIdUserMixin, SupportsDarkMode],

	props: {
		content: {
			type: Object,
			default: () => {},
		},
		dark: {
			type: Boolean,
			default: false,
		},
		propertySearchExcluded: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: "s",
		},
		textAfter: {
			type: String,
			default: "",
		},
	},

	data() {
		return {
			query: "",
			previousSearch: "",
			loading: false,
			minHeight: 50,
			showSuggestions: false,
			searchTimeout: null,
			prettyError: null,
			isShakeSearchField: false,
			autofocus: false,
		};
	},

	computed: {
		...mapGetters("fixedOrder/fixedOrderFlow", ["currentFlow"]),
    ...mapState(useAddressStore, ['suggestions', "lastSearchedAddress", "error", "apartmentsError", "suggestionsError"]),
		noHitsOnQuery() {
			return !this.suggestions.length && this.showSuggestions && this.query && !this.loading;
		},
		formattedSuggestions() {
			if (this.noHitsOnQuery) {
				return ["Ingen treff"];
			} else if (this.suggestions.length && this.showSuggestions) {
				return this.suggestions
					.map((address) => ({
						...address,
					}))
					?.map((suggestion) => suggestion?.formattedAddress);
			} else {
				return [];
			}
		},
		secondaryCtaButton() {
			return {
				text: this.content.secondaryCtaButton?.text,
				link: LinkViewModel(this.content.secondaryCtaButton?.link),
			};
		},
	},

	methods: {
		async handleInput(input) {
			this.loading = true;
			this.query = input;
			if (this.searchTimeout) {
				clearTimeout(this.searchTimeout);
			}
			if (!this.query) {
				this.reset();
				this.loading = false;
			}
			// If at least 3 characters have changed since the last search performed (excluding spaces), perform an immediate search
			else if (isMinimumDifferent(this.previousSearch, this.query, 3)) {
				await this.getSuggestions();
			} else {
				this.searchTimeout = setTimeout(() => {
					if (this.query !== "") {
						this.getSuggestions();
					}
				}, 1000);
			}
		},
		clearSuggestions() {
			this.showSuggestions = false;
			clearTimeout(this.searchTimeout);
		},
		async getSuggestions() {
			this.loading = true;
			this.previousSearch = this.query;
      await useAddressStore().fetchSuggestions(encodeURIComponent(this.query));
			this.loading = false;
		},

		reset() {
      useAddressStore().resetData();
		},
		async fetchAddress(index) {
      useAddressStore().lastSearchedAddress = this.suggestions[index]?.formattedAddress;
      await useAddressStore().fetchAddressAndApartments(this.suggestions[index]?.id);
			this.loading = false;
		},
		async suggestionSelected(idx) {
			if (this.suggestions.length && !this.noHitsOnQuery) {
				this.showSuggestions = false;
				this.loading = true;
				await this.fetchAddress(idx);
				this.$emit("suggestionSelected");
			}
		},
		handleFocus() {
			this.autofocus = true;
			this.showSuggestions = true;
			this.$nextTick(() => {
				this.autofocus = false;
			});
		},
		ctaClicked() {
			this.handleFocus();
			this.shakeSearchField();
		},
		secondaryCtaClicked() {
			this.shakeSearchField();
		},
		shakeSearchField() {
			this.isShakeSearchField = true;
			setTimeout(() => {
				this.isShakeSearchField = false;
			}, 200);
		},
	},

	watch: {
		apartmentsError(newVal) {
			this.prettyError = FormatToPrettyError.messageAndCodes(newVal);
		},
		suggestionsError(newVal) {
			this.prettyError = FormatToPrettyError.messageAndCodes(newVal);
		},
		error(newVal) {
			this.prettyError = FormatToPrettyError.messageAndCodes(newVal);
		},
	},

	async mounted() {
		this.telenorIdUser = await telenorid.getOrLoginUser();
	},
});
</script>

<style lang="scss" scoped>
@use "sass:color";

.input {
	@function hexToRGB($hex) {
		@return color.channel($hex, "red", $space: rgb), color.channel($hex, "green", $space: rgb),
			color.channel($hex, "blue", $space: rgb);
	}

	:deep(.suggestions) {
		--dropdown-background-color: #{$color-neutrals-white};
		--dropdown-background-color-hover: rgba(#{hexToRGB($color-cta-hover-background)}, 0.08);
		--dropdown-background-color-focus: rgba(#{hexToRGB($color-cta-focus)}, 1);
		--dropdown-color-focus: #{$color-neutrals-white};

		&.dark {
			--dropdown-background-color: #37486b;
			--dropdown-background-color-hover: rgba(#{hexToRGB($color-cta-dark-hover-background)}, 0.08);
			--dropdown-background-color-focus: rgba(#{hexToRGB($color-cta-dark-focus)}, 1);
			--dropdown-color-focus: #{$color-primary-dark};
		}
	}
}

.address-search {
	padding-bottom: -16px;
	&.isShakeSearchField {
		animation: shake 200ms;
	}
	@keyframes shake {
		0% {
			transform: translateX(0);
		}
		25% {
			transform: translateX(-5px);
		}
		50% {
			transform: translateX(5px);
		}
		75% {
			transform: translateX(-5px);
		}
		100% {
			transform: translateX(0);
		}
	}
}

.slide-fade-enter-active {
	transition: all 0.3s ease;
}
</style>
